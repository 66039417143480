<template>
  <v-app>
    <v-app-bar app color="black" dark dense>
      <h2>99' nasty kidz</h2>
      <v-spacer></v-spacer>
      <!-- <v-btn
        href="https://www.youtube.com/@99NastyKidz"
        target="_blank"
        text
      >
        <span class="mr-2">Youtube Channe</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
