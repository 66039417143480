<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="text-center">Fan page under development</h1>
        <h3 class="text-center">Until April 15, 2024</h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>
